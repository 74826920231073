import React, { useState, useEffect, useRef } from "react";
import { DatePicker as AntDatePicker, Button, Space } from "antd";
import dayjs from "dayjs";
import locale_ar from "antd/es/date-picker/locale/ar_EG";
import locale_en from "antd/es/date-picker/locale/en_US";
import "dayjs/locale/ar"; // Import the Arabic locale for dayjs
import "./_Picker.scss";
import { useTranslation } from "react-i18next";
const date_format = "YYYY/MM/DD";

// import cookies from "js-cookie";

const CustomDatePicker = ({
  currentLanguage = "ar",
  availableDays,
  all_days_available,
  all_days_available_price,
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(null);
  const [values, setValues] = useState([]);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  //   const currentLanguageCode = cookies.get("i18next") || "en";

  const currentYear = new Date().getFullYear();
  const defaultDatesWithPrice = Array.from({ length: 365 }, (_, index) => {
    const date = dayjs(`${currentYear}-01-01`).add(index, "day");
    const formattedDate = date.format(date_format);

    return {
      date: formattedDate,
      price: all_days_available_price,
    };
  });
  useEffect(() => {
    dayjs.locale(currentLanguage);
  }, [currentLanguage]);

  function findDateInArray(dateToFind, array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].date === dateToFind) {
        return {
          found: true,
          index: i,
          price: array[i].price,
        };
      }
    }
    return {
      found: false,
      index: -1,
      price: null,
    };
  }

  useEffect(() => {
    if (all_days_available) {
      setValues(defaultDatesWithPrice);
    } else {
      setValues(availableDays);
    }
  }, [availableDays]);
  const handleButtonClick = (e) => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };
  return (
    <div className="calendar_multiple">
      <Space direction="vertical">
        <button className="range_absolute_btn" onClick={handleButtonClick}>
          {t("available_dates")}
        </button>
        <AntDatePicker
          style={{ visibility: "hidden", width: 0 }}
          className="calendar_section"
          value={value}
          format={date_format}
          locale={currentLanguage === "ar" ? locale_ar : locale_en}
          showToday={false}
          placeholder="Select Date"
          open={isDatePickerOpen}
          cellRender={(date) => (
            <div
              className={
                findDateInArray(dayjs(date).format(date_format), values)?.found
                  ? "active_day"
                  : "normal_day"
              }
            >
              <div className={"day"}>{dayjs(date).format("D")}</div>
              {findDateInArray(dayjs(date).format(date_format), values)
                ?.found && (
                <p className="price_day_calendar">
                  {
                    findDateInArray(dayjs(date).format(date_format), values)
                      ?.price
                  }
                  $
                </p>
              )}{" "}
            </div>
          )}
          // renderExtraFooter={() => <div className="footer">footer</div>}
          // onOk={() => false}
          // onSelect={() => false}
          // onPanelChange={() => false}
          // onChange={() => false}
          onOpenChange={(status) => {
            setIsDatePickerOpen(status);
          }}
        />
        <div>
          {/* {value && (
          <div className="day">
            <div className="day_v1 lined_date">
              {getDayTranslation(dayjs(value).day(), currentLanguage)}
            </div>
            <p>100</p>
          </div>
        )} */}
        </div>
      </Space>
    </div>
  );
};

export default CustomDatePicker;

// import { useEffect, useState } from "react";
// import DatePicker from "react-multi-date-picker";
// import arabic_ar from "react-date-object/locales/arabic_ar";
// import english_en from "react-date-object/locales/gregorian_en";
// import "./_CalendarDates.scss";
// import dayjs from "dayjs";
// import { useTranslation } from "react-i18next";
// import cookies from "js-cookie";

// let date_format = "YYYY-MM-DD";
// export default function CalendarDates({
//   availableDays,
//   all_days_available,
//   all_days_available_price,
// }) {
//   const { t } = useTranslation();
//   const [values, setValues] = useState([]);
//   const currentLanguageCode = cookies.get("i18next") || "en";

//   const currentYear = new Date().getFullYear();

//   const defaultDatesWithPrice = Array.from({ length: 365 }, (_, index) => {
//     const date = dayjs(`${currentYear}-01-01`).add(index, "day");
//     const formattedDate = date.format(date_format);

//     return {
//       date: formattedDate,
//       price: all_days_available_price,
//     };
//   });

//   useEffect(() => {
//     if (all_days_available) {
//       setValues(defaultDatesWithPrice);
//     } else {
//       setValues(availableDays);
//     }
//   }, [availableDays]);

//   function findDateInArray(dateToFind, array) {
//     for (let i = 0; i < array.length; i++) {
//       if (array[i].date === dateToFind) {
//         return {
//           found: true,
//           index: i,
//           price: array[i].price,
//         };
//       }
//     }
//     return {
//       found: false,
//       index: -1,
//       price: null,
//     };
//   }

//   return (
//     <DatePicker
//       arrow={false}
//       highlightToday={false}
//       multiple
//       value={values?.map((el) => el?.date)}
//       className="calendar_section"
//       dateSeparator=" & "
//       onChange={() => {}}
//       mapDays={({ date }) => {
//         return {
//           children: (
//             <div className="day">
//               <div
//                 className={
//                   findDateInArray(dayjs(date).format(date_format), values)
//                     ?.found
//                     ? "day_v1 lined_date"
//                     : "day_v1"
//                 }
//               >
//                 {date.format("D")}
//               </div>
//               {findDateInArray(dayjs(date).format(date_format), values)
//                 ?.found && (
//                 <p className="price_day_calendar">
//                   {
//                     findDateInArray(dayjs(date).format(date_format), values)
//                       ?.price
//                   }
//                   $
//                 </p>
//               )}
//             </div>
//           ),
//         };
//       }}
//       locale={currentLanguageCode === "en" ? english_en : arabic_ar}
//       render={
//         <button className="btn available_days">
//           {t("daily_rent.available_days")}{" "}
//         </button>
//       }
//     />
//   );
// }
