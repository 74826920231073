import React from "react";
import "./_ActionsCard.scss";
import { ReactComponent as PhoneIcon } from "../../../../assets/images/ManagementSaleOrg/phone.svg";
import { ReactComponent as WhatsIcon } from "../../../../assets/images/ManagementSaleOrg/whats.svg";
import { useTranslation } from "react-i18next";
import CalendarDates from "../../../../components/CalendarDates/CalendarDates";
import cookies from "js-cookie";
function ActionCard({ propertieItem }) {
  const { t } = useTranslation();

  const lang = cookies.get("i18next") || "en";

  return (
    <div className="more-info-container">
      {propertieItem?.offer_type === "sell" && (
        <button className="btn">
          {t("properties.Toll")} {propertieItem?.price?.toll_price?.price}{" "}
          {propertieItem?.currency_key}
          <span>
            {propertieItem?.price?.toll_price?.price_per_meter}{" "}
            {t("properties.RS_meter")}
          </span>
        </button>
      )}

      {propertieItem?.offer_type === "rent" && (
        <div className="pay-method">
          <p className="pay_m">{t("properties.Payment method")} </p>{" "}
          {propertieItem?.price?.payment_method
            ?.split(",")
            ?.map((item, index) => (
              <span key={index} className="pay_tag">
                {item}
              </span>
            ))}
        </div>
      )}

      {propertieItem?.offer_type === "daily_rent" &&
      propertieItem?.available &&
      propertieItem?.property_default_price != 0 ? (
        <button className="btn">
          {propertieItem?.property_default_price} {propertieItem?.currency_key}
        </button>
      ) : (
        <span className="no-available-title"></span>
      )}
      <div className="contact-info">
        {propertieItem?.user_details?.selling_agent?.image && (
          <img
            src={propertieItem?.user_details?.selling_agent?.image}
            alt="logo"
            className="logo_property"
          />
        )}
        <div className="contact-btn">
          <a href={`tel:${propertieItem?.phone}`}>
            <PhoneIcon />
          </a>
        </div>
        <div className="contact-btn">
          <a
            href={`https://api.whatsapp.com/send?phone=${propertieItem?.whatsapp}`}
          >
            <WhatsIcon />
          </a>
        </div>
        {propertieItem?.offer_type === "daily_rent" && (
          <>
            <CalendarDates
              availableDays={propertieItem?.available_days}
              all_days_available={propertieItem?.all_days_available}
              all_days_available_price={propertieItem?.property_default_price}
              currentLanguage={lang}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default ActionCard;
