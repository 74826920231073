const Checkbox = ({ label, name, value, checked = false, onClick }) => {
  return (
    <div className='checkbox-input' onClick={onClick}>
      <input type='checkbox' id={name} name={name} value={value} checked={checked} />
      <label for={name}>{label}</label>
    </div>
  );
};

export default Checkbox;
