import React from "react";
import "./PriceCard.scss";
import { useTranslation } from "react-i18next";

function PriceCard({ propertieItem }) {
  const { t } = useTranslation("translation");

  return (
    <div className="data-container price">
      {propertieItem?.offer_type === "sell" && (
        <>
          <span className="data-title">
            {propertieItem?.price?.sell?.price} {propertieItem?.currency_key}
          </span>
          <span className="data-subtitle">
            {propertieItem?.price?.sell?.price_per_meter}{" "}
            {propertieItem?.currency_key}
            {t("properties.RS_meter")}
          </span>
        </>
      )}

      {propertieItem?.offer_type === "rent" && (
        <>
          {propertieItem?.price?.monthly?.price ? (
            <>
              {" "}
              <span className="data-title">
                {propertieItem?.price?.monthly?.price}{" "}
                {propertieItem?.currency_key}
              </span>
              <span className="data-per-year">
                {propertieItem?.price?.yearly?.price}{" "}
                {propertieItem?.currency_key} {t("per Year")}
              </span>{" "}
            </>
          ) : (
            <span className="no-available-title">
              {t("no_available_price")}
            </span>
          )}
        </>
      )}
      {propertieItem?.offer_type === "daily_rent" && (
        <>
          <span
            className={
              propertieItem?.available
                ? "data-title"
                : "data-title not_available"
            }
          >
            {propertieItem?.available
              ? t("daily_rent.availability")
              : t("daily_rent.non_availability")}
          </span>
          {propertieItem?.available &&
          propertieItem?.property_default_price != 0 &&
          propertieItem?.property_default_price &&
          propertieItem?.property_default_price != "" ? (
            <span className="data-per-year">
              {propertieItem?.property_default_price}{" "}
              {propertieItem?.currency_key}
            </span>
          ) : (
            <span className="no-available-title">
              {t("no_available_price")}
            </span>
          )}
        </>
      )}
    </div>
  );
}

export default PriceCard;
