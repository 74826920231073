import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offer_type: "sell",

  // Specifications
  unit_usage: "", // commercial|residential|families|singles
  area_by_square_meter_range: null, //1-2
  area_by_length_range: null, //1-2
  area_by_width_range: null, //1-2
  area_by_height_range: null, //1-2
  building_first_inhabited: "", //yes or no,
  building_age_in_years_range: null, //5-8
  include_mezzanine: "", //yes or ""
  entrance: "", //private or shared
  unit_finishing: "", //shell|finished|unfinished
  unit_direction: "", //north|east|west|south
  is_furnished: "", // if check pass yes else no
  sea_view: "", // if check pass yes else no
  floor: "", //1-2

  // Rooms
  total_rooms_range: null,
  bedrooms: "",
  bedrooms_full: "",
  bathrooms: "",
  halls: "",
  open_kitchen: "",
  saperate_kitchen: "",
  majles: "",
  storeroom: "",
  extension: "",
  roof_apartment: "",
  frontyard: "",
  frontyard_range: null,
  backyard: "",
  backyard_range: null,
  sideyard: "",
  sideyard_range: null,
  grass_area: "",
  grass_area_range: null,
  tent: "",
  tent_range: null,
  fixed_caravan: "",
  fixed_caravan_range: null,
  swimming_pool: null,
  football_stadium: null,
  driver_room: null,
  maid_room: null,
  car_park: null,

  outdoor_patio_square_meter_range: null,

  // Services
  electricity_service: "",
  water_service: "",
  internet_can_install: "",
  internet_available: "",
  landline: "",
  guard_service: "",
  reception_service: "",

  // Facilities
  central_air_conditioner: "",
  central_air_conditioner_range: null,
  split_unit: "",
  split_unit_range: null,
  window_air_conditioner: "",
  window_air_conditioner_range: null,
  desert_cooler: "",
  desert_cooler_range: null,
  palm: "",
  palm_range: null,
  ornamental_trees: "",
  ornamental_trees_range: null,
  fruitful_trees: "",
  fruitful_trees_range: null,
  is_kitchen_sink_installed: "",
  is_cabinet_installed: "",
  private_elevator: "",
  shared_elevator: "",
  wood_stove: "",

  // Fourniture
  furnished_type: "",
  iron: null,
  microwave_heating_food: null,
  coffee_machine: null,
  oven: null,
  refrigerator: null,
  dishwasher: null,
  washing_machine: null,

  // Location
  country_id: "", // when login pass user country id
  country_code: "", //when not login pass IP address country code
  country: null,
  city: "",
  area: null,
  type_name: "", // property type

  // Pricing
  minprice: "", //sale price range min value
  maxprice: "", //sale price range max value
  price_per_meter_range: null, //1-2

  // OK
  sort: null, //low-to-high|high-to-low
  search: null, //any search text
  tents_range: "", //1-2

  price_per_meter_daily_range: null, //1-2
  price_per_meter_monthly_range: null, //1-2
  price_per_meter_yearly_range: null, //1-2
  pid: null, //property id
  holding: "", //dailt|monthly|yearly
  offer: "", //buy|rent|daily_rent|auction
  sa_id: null, // selling agent id

  state: null,
  start_date: null, //start date for daily rent
  end_date: null, //end date for daily rent

  toll_price_per_meter_range: null,
  sell_available: null,
  toll_available: null,
  rent_available: null,
  payment_method: null,
  type: "1",
  //daily rent filters
  daily_price_range: null, //value :: "18750-50250"
  daily_total_range: null, //value :: "18750-50250"
  daily_from_date: null, //start date value :: "2023-10-11"
  daily_to_date: null, //end date value :: "2023-10-15"
  sorting: null, // most_recent , oldest ---- available_first , available_last
  smoking_allowed: null, // values :: yes or no
};

export const brokerFiltersSlice = createSlice({
  name: "brokerFilters",
  initialState,
  reducers: {
    handleStateChange: (state, action) => {
      const { target, value } = action.payload;
      if (state[target] === value) {
        state[target] = null;
      } else {
        state[target] = value;
      }
    },
    handleStateChangeMultiple: (state, action) => {
      const { target, value } = action.payload;

      if (state[target]?.includes(value)) {
        state[target] = state[target]
          ?.replace(value, "")
          ?.split(",")
          ?.filter((elm) => elm)
          ?.join(",");
      } else {
        let newArr = state[target]?.split(",")?.filter((elm) => elm);
        newArr?.push(value);
        state[target] = newArr?.join(",");
      }
    },
    resetFilters: (state) => {
      state = initialState;
    },
    setCountryInfo: (state, action) => {
      const { country_id, country_code } = action.payload;
      state.country_id = country_id;
      state.country_code = country_code;
    },
  },
});

export const {
  handleStateChange,
  handleStateChangeMultiple,
  resetFilters,
  setCountryInfo,
} = brokerFiltersSlice.actions;

export default brokerFiltersSlice.reducer;
