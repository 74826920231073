import Location from "./components/Location/Location";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/Filters/arrow up.svg";
import { useState } from "react";
import Pricing from "./components/Pricing/Pricing";
import Specifications from "./components/Specifications/Specifications";
import Rooms from "./components/Rooms/Rooms";
import Services from "./components/Services/Services";
import Facilities from "./components/Facilities/Facilities";
import Fourniture from "./components/Fourniture/Fourniture";
import { useTranslation } from "react-i18next";
// import useWindowSize from '../../hooks/useWindowSize';
// import closeIcon from '../../assets/icons/close1.svg';
// import { closeFilter } from '../../data/slices/settingsSlice';
import { useDispatch, useSelector } from "react-redux";
const Filters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pricingCollasped, setPriceCollasped] = useState(false);
  const [specificationsCollasped, setSpecificationsCollasped] = useState(false);
  const [roomsCollasped, setRoomsCollasped] = useState(false);
  const [servicesCollasped, setServicesCollasped] = useState(false);
  const [facilitiesCollasped, setFacilitiesCollasped] = useState(false);
  const [fournitureCollasped, setFournitureCollasped] = useState(false);
  // const { width } = useWindowSize();
  const { specifications } = useSelector((state) => state.properties);
  return (
    <div className="filter-container">
      {/* {width > 1255 ? (
        <h3 className='title'>{t('filters.Filter_Search')}</h3>
      ) : (
        <img
          className='close_icon'
          onClick={() => dispatch(closeFilter())}
          src={closeIcon}
          alt=''
        />
      )} */}

      {/* Location filters */}
      <Location />

      {/* Pricing filters */}
      <button
        className="collapse-btn"
        onClick={() => setPriceCollasped(!pricingCollasped)}
      >
        {t("filters.Pricing_filters")}
        <ArrowUpIcon className={pricingCollasped ? "flip-arrow" : ""} />
      </button>

      {!pricingCollasped ? (
        <div className="filters-content-container">
          <Pricing />
        </div>
      ) : null}

      {/* Specifications filters */}

      {specifications?.type_of_unit !== "1" &&
      specifications?.unit_area !== "1" &&
      specifications?.unit_length !== "1" &&
      specifications?.unit_width !== "1" &&
      specifications?.unit_height !== "1" &&
      specifications?.building_no !== "1" &&
      specifications?.building_age_in_years !== "1" &&
      specifications?.include_mezzanine !== "1" &&
      specifications?.private_entrance_or_shared !== "1" &&
      specifications?.unit_number !== "1" &&
      specifications?.total_floors !== "1" &&
      specifications?.unit_direction !== "1" &&
      specifications?.furnished_type !== "1" &&
      specifications?.sea_view !== "1" ? null : (
        <button
          className="collapse-btn"
          onClick={() => setSpecificationsCollasped(!specificationsCollasped)}
        >
          {t("filters.Specifications_filters")}
          <ArrowUpIcon
            className={specificationsCollasped ? "flip-arrow" : ""}
          />
        </button>
      )}
      {!specificationsCollasped ? (
        <div className="filters-content-container">
          <Specifications />
        </div>
      ) : null}

      {/* Specifications filters */}

      {specifications?.number_of_rooms !== "1" &&
      specifications?.bedrooms !== "1" &&
      specifications?.bathrooms !== "1" &&
      specifications?.halls !== "1" &&
      specifications?.open_kitchen !== "1" &&
      specifications?.saperate_kitchen !== "1" &&
      specifications?.majles !== "1" &&
      specifications?.storeroom !== "1" &&
      specifications?.extension !== "1" &&
      specifications?.roof_apartment !== "1" &&
      specifications?.frontyard !== "1" &&
      specifications?.backyard !== "1" &&
      specifications?.sideyard !== "1" &&
      specifications?.grass_area !== "1" &&
      specifications?.tent !== "1" &&
      specifications?.fixed_caravan !== "1" &&
      specifications?.swimming_pool !== "1" &&
      specifications?.maid_room !== "1" &&
      specifications?.football_stadium !== "1" &&
      specifications?.car_park !== "1" &&
      specifications?.driver_room !== "1" ? null : (
        <button
          className="collapse-btn"
          onClick={() => setRoomsCollasped(!roomsCollasped)}
        >
          {t("filters.Rooms_and_others_filters")}
          <ArrowUpIcon className={roomsCollasped ? "flip-arrow" : ""} />
        </button>
      )}

      {!roomsCollasped ? (
        <div className="filters-content-container">
          <Rooms />
        </div>
      ) : null}

      {/* Services filters */}

      {specifications?.electricity_service !== "1" &&
      specifications?.water_service !== "1" &&
      specifications?.internet_dsl !== "1" &&
      specifications?.internet_fiber !== "1" &&
      specifications?.landline !== "1" &&
      specifications?.guard_service !== "1" &&
      specifications?.reception_service !== "1" ? null : (
        <button
          className="collapse-btn"
          onClick={() => setServicesCollasped(!servicesCollasped)}
        >
          {t("filters.Services_filters")}
          <ArrowUpIcon className={servicesCollasped ? "flip-arrow" : ""} />
        </button>
      )}

      {!servicesCollasped ? (
        <div className="filters-content-container">
          <Services />
        </div>
      ) : null}

      {/* Services filters */}

      {specifications?.central_air_conditioner !== "1" &&
      specifications?.split_unit !== "1" &&
      specifications?.window_air_conditioner !== "1" &&
      specifications?.shared_elevator !== "1" &&
      specifications?.desert_cooler !== "1" &&
      specifications?.palm !== "1" &&
      specifications?.ornamental_trees !== "1" &&
      specifications?.fruitful_trees !== "1" &&
      specifications?.is_kitchen_sink_installed !== "1" &&
      specifications?.is_cabinet_installed !== "1" &&
      specifications?.private_elevator !== "1" &&
      specifications?.wood_stove !== "1" ? null : (
        <button
          className="collapse-btn"
          onClick={() => setFacilitiesCollasped(!facilitiesCollasped)}
        >
          {t("filters.Facilities_filters")}
          <ArrowUpIcon className={facilitiesCollasped ? "flip-arrow" : ""} />
        </button>
      )}

      {!facilitiesCollasped ? (
        <div className="filters-content-container">
          <Facilities />
        </div>
      ) : null}

      {/* Services filters */}

      {specifications?.furnished_type === "0" &&
      specifications?.washing_machine === "0" &&
      specifications?.dishwasher === "0" &&
      specifications?.refrigerator === "0" &&
      specifications?.oven === "0" &&
      specifications?.coffee_machine === "0" &&
      specifications?.iron === "0" &&
      specifications?.microwave_heating_food === "0" &&
      specifications?.oven === "0" ? null : (
        <button
          className="collapse-btn"
          onClick={() => setFournitureCollasped(!fournitureCollasped)}
        >
          {t("filters.Fourniture_filters")}
          <ArrowUpIcon className={fournitureCollasped ? "flip-arrow" : ""} />
        </button>
      )}
      {!fournitureCollasped ? (
        <div className="filters-content-container">
          <Fourniture />
        </div>
      ) : null}
    </div>
  );
};

export default Filters;
